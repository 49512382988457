<template>
  <div>
    <div class="modal" id="paymentmodal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="overflow: scroll;height: 500px;">
          <div class="card mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-label">Kart Üzerindeki İsim</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="cardName"
                      placeholder="Kart üzerindeki isim"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Kart Numarası</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="cardNumber"
                      v-mask="'XXXXXXXXXXXXXXXX'"
                      placeholder="________________"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Son Kullanım (Ay)</label>
                        <select v-model="cardExpiryMonth" class="form-control">
                          <option value="AA">AA</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Son Kullanım (Yıl)</label>
                        <select v-model="cardExpiryYear" class="form-control">
                          <option value="YY">YY</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">CV Kodu</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="cardCV"
                          v-mask="'XXX'"
                          placeholder="CV Kodu"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-html="installment" v-if="payment == null"></div>
                  <button class="btn btn-primary w-100" @click="pay">
                    Ödeme Yap
                  </button>
                </div>
              </div>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="paymentPay"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <iframe
            :src="payment"
            style="width: 100%; height: 400px"
            v-if="payment != null"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.text {
  text-align: right;
  font-size: 20px;
}
hr {
  border-top: 1px dashed #ccc;
}
</style>
  <script>
export default {
  props: ["selectedExperiences", "currentAppointmentProgress"],
  created() {
    document.title = "Ödeme Yap";
    this.getLink();
    $("#paymentPay").modal({
      backdrop: "static",
      keyboard: false,
    });
  },
  data() {
    return {
      cardName: "",
      cardNumber: "",
      cardExpiryMonth: "AA",
      cardExpiryYear: "YY",
      cardCV: "",
      installment: "",
      payment: null,
      paymentInfo: [],
    };
  },
  watch: {
    cardNumber: function () {
      if (this.cardNumber.length == 6) {
        this.$store
          .dispatch("paymentInstallment", {
            bin: this.cardNumber,
            price: this.selectedExperiences.price,
          })
          .then((value) => {
            this.installment = value;
          });
      }
    },
  },
  methods: {
    getLink() {
      let info = {
        id: this.$route.params.id,
      };
      this.$store.dispatch("educationPaymentGet", info).then((value) => {
        this.paymentInfo = value;
      });
    },
    pay() {
      if (this.cardName == null || this.cardName == "") {
        /**KART ADI BOŞ OLAMAZ */
        this.$vToastify.warning("Kart Sahibi Adı Girmediniz", "Uyarı!");
      } else if (this.cardNumber == null || this.cardNumber == "") {
        /***KART NUMARASI BOŞ OLAMAZ */
        this.$vToastify.warning("Kart Numarası Girmediniz", "Uyarı!");
      } else if (this.cardExpiryMonth == null || this.cardExpiryMonth == "AA") {
        /***KART SK AY BOŞ OLAMAZ */
        this.$vToastify.warning("Son Kullanım Ay Girmediniz", "Uyarı!");
      } else if (this.cardExpiryYear == null || this.cardExpiryYear == "YY") {
        /***KART SK YIL BOŞ OLAMAZ */
        this.$vToastify.warning("Son Kullanım Yıl Girmediniz", "Uyarı!");
      } else if (this.cardCV == null || this.cardCV == "") {
        /***KART SK CV BOŞ OLAMAZ */
      } else {
        /** */
        this.$store
          .dispatch("paymentPay", {
            cardName: this.cardName,
            cardNumber: this.cardNumber,
            cardExpiryMonth: this.cardExpiryMonth,
            cardExpiryYear: this.cardExpiryYear,
            cardCV: this.cardCV,
            installment: 1,
            billingId:
              this.selectedExperiences.payment_methods[0].experience_combine_id,
            type: "appointment",
            data: this.currentAppointmentProgress,
          })
          .then((value) => {
            this.payment = value;
            $("#paymentmodal").modal("hide");

            $("#paymentPay").modal("show");
          });
      }
    },
  },
  components: {},
  mounted() {
    console.log(this.selectedExperiences);
  },
};
</script>