<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Randevu Bilgileri</h2>
      </div>
      <div class="card-body">
        <template v-if="$store.state.user_id > 0">
          <DateCalendar
            :userid="$store.state.user_id"
            @response="handleSelectedDate($event)"
          />
        </template>
        <div class="form-group">
          <label class="form-label text-dark">Hizmet Seçin *</label>
          <select
            v-model="selectedExperiences"
            :disabled="selectedDate == '' ? true : false"
            class="form-control"
          >
            <option
              v-for="(a, i) in experiences"
              :key="'experience' + i"
              :value="a"
            >
              {{ a.experience_name }}
            </option>
          </select>
        </div>

        <div class="form-group" v-if="this.selectedExperiences.length != 0">
          <label class="form-label text-dark">Görüşme Yöntemi *</label>
          <select v-model="selectedMethods" class="form-control">
            <option value="0" selected>Görüşme Yöntemi Seç</option>
            <option
              :selected="selectedMethodControl('F2F')"
              value="1"
              v-if="selectedExperiences.face_to_face == 1"
            >
              Yüz Yüze Görüşme
            </option>
            <option
              value="2"
              :selected="selectedMethodControl('HOME')"
              v-if="selectedExperiences.home_service == 1"
            >
              Evde Görüşme
            </option>
            <option
              :selected="selectedMethodControl('ONLINE')"
              value="3"
              v-if="selectedExperiences.online == 1"
            >
              Online Görüşme
            </option>
          </select>
        </div>
        <div
          class="form-group"
          v-if="
            this.selectedExperiences.length != 0 &&
            this.selectedExperiences.pre_meeting == 1
          "
        >
          <div
            class="form-check form-switch mb-2"
            style="padding-left: 29px"
            :key="'cb-' + i"
          >
            <input
              type="checkbox"
              class="form-check-input"
              name="checkbox6"
              id="pre_meeting"
              @click="preMeeting"
            />
            <label class="form-check-label" for="pre_meeting"
              >Ücretsiz Ön Randevu
            </label>
          </div>
        </div>
        <div class="form-group" v-if="this.selectedExperiences.length != 0">
          <label class="form-label text-dark">Ödeme Yöntemi Seçin *</label>
          <select v-model="selectedPaymentMethod" class="form-control">
            <option :value="[]" selected disabled>Ödeme Yöntemi Seç</option>
            <option
              v-for="(a, i) in this.selectedExperiences.payment_methods"
              :key="'payment_methods' + i"
              :selected="selectedMethod()"
              :value="a"
            >
              {{ a.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label text-dark">Ad Soyad *</label>
          <input
            type="text"
            class="form-control"
            name="fullname"
            v-model="fullname"
            :disabled="selectedDate == '' ? true : false"
            id="fullname"
          />
        </div>
        <div class="form-group">
          <label class="form-label text-dark">Telefon *</label>
          <input
            type="text"
            v-mask="'0(###) ###-##-##'"
            v-model="phone"
            placeholder="0(_ _ _) _ _ _-_ _-_ _"
            class="form-control"
            :disabled="selectedDate == '' ? true : false"
            name="phone"
            id="phone"
          />
        </div>

        <div
          class="row"
          v-if="selectedExperiences != null && selectedMethods == 2"
        >
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Adres *</label>
              <textarea
                type="text"
                class="form-control"
                name="address"
                v-model="address"
                :disabled="selectedDate == '' ? true : false"
                placeholder="Adres"
              ></textarea>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İl *</label>
              <select
                name="city"
                id="city"
                class="form-control"
                :disabled="selectedDate == '' ? true : false"
                @change="cityChange"
              >
                <optgroup label="Şehir Seçiniz">
                  <option
                    v-for="(item, i) in cityList"
                    :key="i"
                    :value="item.name"
                    :data-province="JSON.stringify(item.districts)"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İlçe *</label>
              <select
                name="province"
                id="province"
                class="form-control"
                :disabled="selectedDate == '' ? true : false"
              >
                <optgroup label="İlçe Seçiniz">
                  <option
                    v-for="(item, i) in selectedDistricts"
                    :key="i"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Açıklama</label>
          <textarea
            :disabled="selectedDate == '' ? true : false"
            type="text"
            class="form-control"
            name="description"
            v-model="description"
          ></textarea>
        </div>
        <hr />
        <button
          type="button"
          id="saveButton"
          :disabled="selectedDate == '' ? true : false"
          class="btn btn-primary w-100"
          @click="save"
        >
          <span class="indicator-label">Devam Et</span>
        </button>
      </div>
    </div>
    <PaymentModal
      @response="paymentData($event)"
      :selectedExperiences="selectedExperiences"
      :currentAppointmentProgress="currentAppointmentProgress"
    />
  </div>
</template>
<script>
import PaymentModal from "../../../Appointment/Components/PaymentModal.vue";
import DateCalendar from "./Components/DateCalendar.vue";
export default {
  created() {
    document.title = "Randevu Oluştur";
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        this.selectedDistricts = value.list[0].districts;
      });
    }
  },
  data() {
    return {
      user: [],
      calenderToggleBool: false,
      phone: "",
      cityList: [],
      selectedDistricts: [],
      pre_meeting: 0,
      checkReturn: false,
      description: "",
      selectedDate: "",
      selectedDateId: "",
      fullname: "",
      address: "",
      description: "",
      experiences: [],
      selectedPaymentMethod: [],
      currentAppointmentProgress: [],
      selectedMethods: 0,
      selectedExperiences: [],
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
    };
  },
  watch: {},
  methods: {
    selectedMethod() {
      if (this.selectedExperiences.payment_methods.length == 1) {
        this.selectedPaymentMethod =
          this.selectedExperiences.payment_methods[0];
        return true;
      } else {
        return false;
      }
    },
    selectedMethodControl(type) {
      if (type == "F2F") {
        if (
          this.selectedExperiences.face_to_face == 1 &&
          this.selectedExperiences.online == 0 &&
          this.selectedExperiences.home_service == 0
        ) {
          this.selectedMethods = 1;
          return true;
        }
      }
      if (type == "ONLINE") {
        if (
          this.selectedExperiences.face_to_face == 0 &&
          this.selectedExperiences.online == 1 &&
          this.selectedExperiences.home_service == 0
        ) {
          this.selectedMethods = 3;
          return true;
        }
      }
      if (type == "HOME") {
        if (
          this.selectedExperiences.face_to_face == 0 &&
          this.selectedExperiences.online == 0 &&
          this.selectedExperiences.home_service == 1
        ) {
          this.selectedMethods = 2;
          return true;
        }
      }
      return false;
    },
    preMeeting() {
      if (this.pre_meeting == 0) {
        //ÖN RANDEVUYU ALABİLİR Mİ
        this.$store
          .dispatch("preMeetingCheck", {
            selectedMethods: this.selectedExperiences.experience_id,
            expert_id: this.user.id,
          })
          .then((e) => {
            if (e.type == "success") {
              this.pre_meeting = 1;
            } else {
              this.$vToastify.warning(
                "Daha önce ön randevu oluşturmuşsunuz!",
                "Uyarı!"
              );
              document.getElementById("pre_meeting").checked = false;
            }
          })
          .catch((e) => {
            this.$vToastify.warning(
              "Üyelik olmadan ön randevu talepleri oluşturulamaz",
              "Uyarı!"
            );
            document.getElementById("pre_meeting").checked = false;
          });
      } else {
        this.pre_meeting = 0;
        document.getElementById("pre_meeting").checked = false;
      }
    },
    paymentData: function (e) {
      this.checkReturn = e;
    },
    dateControl(id, date) {
      let appointmentControl = {
        id: id,
        date: date,
      };
      this.$store
        .dispatch("appointmentControl", appointmentControl)
        .then((value) => {
          if (value.type == "success") {
            this.user = value.user;
            this.experiences = value.experiences;
            this.loading = false;
          } else {
            if (value.message == "ERRxUDF") {
              this.$vToastify.warning("Hatalı tarih formatı", "Uyarı!");
              this.$router.go(-1);
            }
            if (value.message == "ERRxDEX") {
              this.$vToastify.warning(
                "Randevu süresi geçmişten bir gün olamaz",
                "Uyarı!"
              );
            }
            if (value.message == "ERRxDNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı", "Uyarı!");
            }
          }
        });
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    handleSelectedDate: function (e) {
      this.dateControl(this.$store.state.user_id, e[0]);
      this.selectedDate = e[0];
      this.selectedDateId = e[1];
    },
    handleData: function (e) {
      this.checkReturn = e;
    },
    save() {
      let fullname = document.querySelector("input[name=fullname]").value;
      let address = "";
      let city = "";
      let province = "";
      if (this.selectedExperiences != null && this.selectedMethods == 2) {
        address = document.querySelector("textarea[name=address]").value;
        city = document.querySelector("select[name=city]").value;
        province = document.querySelector("select[name=province]").value;
      }
      let number = this.phone;
      if (fullname == null || fullname == "") {
        this.$vToastify.warning("Ad Soyad Girilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences == null ||
        this.selectedExperiences.length == 0
      ) {
        this.$vToastify.warning("Hizmet türü seçmediniz", "Uyarı!");
      } else if (this.selectedPaymentMethod == null) {
        this.$vToastify.warning("Ödeme yöntemi seçmediniz", "Uyarı!");
      } else if (this.selectedMethods == 0) {
        this.$vToastify.warning("Görüşme yöntemi seçilmedi", "Uyarı!");
      } else if (number == null || number == "") {
        this.$vToastify.warning("Telefon numarası girilmedi", "Uyarı!");
      } else if (!number.match(this.phoneRegex)) {
        this.$vToastify.warning("Telefon numarası hatalı", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (address == null || address == "")
      ) {
        this.$vToastify.warning("Adres girilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (city == null || city == "")
      ) {
        this.$vToastify.warning("Şehir seçilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (province == null || province == "")
      ) {
        this.$vToastify.warning("İlçe seçilmedi", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          '<span class="indicator-progress">Lütfen bekleyiniz...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        this.saveAppointment();
      }
    },
    saveAppointment() {
      let city = "";
      let province = "";
      if (this.selectedExperiences != null && this.selectedMethods == 2) {
        city = document.querySelector("select[name=city]").value;
        province = document.querySelector("select[name=province]").value;
      }

      let appointment = {
        fullname: this.fullname,
        address: this.address,
        phone: this.phone,
        selected_experience_id: this.selectedExperiences.experience_id,
        selected_methods: this.selectedMethods,
        selected_payment_methods: this.selectedPaymentMethod.id,
        city: city,
        member_id: 0,
        province: province,
        description: this.description,
        appointment_hour_id: this.user.appointment_hour_id,
        user_id: this.user.id,
        pre_meeting: this.pre_meeting,
      };

      if (this.selectedPaymentMethod.is_online == 1 && this.pre_meeting == 0) {
        if (this.$store.state.isLogin) {
          //ÖDEME YAPTIR
          this.currentAppointmentProgress = appointment;
          $("#paymentmodal").modal("show");
        } else {
          this.$vToastify.warning(
            "Online ödeme yapabilmek için giriş yapmanız gerekmektedir.",
            "Uyarı!"
          );
        }
      } else {
        this.$store
          .dispatch("appointmentTokenSave", appointment)
          .then((value) => {
            this.$vToastify.success(
              "Randevu başarılı bir şekilde kaydedildi",
              "Başarılı!"
            );
            this.$router.push("/profile/appointments");
          });
      }
    },
  },
  components: { DateCalendar, PaymentModal },
  mounted() {},
};
</script>